.App {
  background: linear-gradient(316deg, #e39656, #b955ac, #7454b5);
  display: flex;
  background-size: 600% 600%;
  align-items: center;
  height: 100vh;
  justify-content: center;
  -webkit-animation: gradientShift 10s ease infinite;
  -moz-animation: gradientShift 10s ease infinite;
  animation: gradientShift 10s ease infinite;
}

@-webkit-keyframes gradientShift {
  0% {
    background-position: 24% 0%;
  }
  50% {
    background-position: 77% 100%;
  }
  100% {
    background-position: 24% 0%;
  }
}

@-moz-keyframes gradientShift {
  0% {
    background-position: 24% 0%;
  }
  50% {
    background-position: 77% 100%;
  }
  100% {
    background-position: 24% 0%;
  }
}

@keyframes gradientShift {
  0% {
    background-position: 24% 0%;
  }
  50% {
    background-position: 77% 100%;
  }
  100% {
    background-position: 24% 0%;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.App-body {
  color: white;
  font-family: Courier, "Courier New", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 20px;
  max-width: 500px;
  margin: auto;
  margin-top: 10vh;
  padding: 20px;
}

h4 {
  color: white;
}
p {
}
